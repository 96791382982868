// axios.js
import Axios from "axios";
import { CONFIG } from "../config/constant";
import { toastError } from "../utils";

const axiosInstance = Axios.create({
  baseURL: `${CONFIG.apiurl}/api`,
  timeout: 10000,
});

const addAuthHeaders = (config) => {
  const token = sessionStorage.getItem("token");
  if (token) {
    config.headers.Authorization = token;
    // config.headers.UniqueId = generateUniqueBrowserId();
  }
  return config;
};

const requestInterceptor = (config, showLoader) => {
  let { shouldShowLoader } = config;
  shouldShowLoader = shouldShowLoader === undefined ? true : shouldShowLoader;
  if (!!shouldShowLoader) {
    showLoader();
  }
  return addAuthHeaders(config);
};

const responseInterceptor = (response, hideLoader) => {
  hideLoader();
  return response;
};

const errorInterceptor = (error, hideLoader) => {
  hideLoader();
  if (error.response.status === 401) {
    console.log("Token Expired Redirect to Login")
    sessionStorage.removeItem("token");
    window.location.reload(`${process.env.PUBLIC_URL}/authentication/login`);
  } else {
    toastError(error.response.data?.message || error.response.data?.error);
  }
  return Promise.reject(error.response.data);
};

export const setupInterceptors = (showLoader, hideLoader, naviagate = null) => {
  axiosInstance.interceptors.request.use((config) =>
    requestInterceptor(config, showLoader)
  );
  axiosInstance.interceptors.response.use(
    (response) => responseInterceptor(response, hideLoader),
    (error) => errorInterceptor(error, hideLoader, naviagate)
  );
};

export default axiosInstance;
