import React, { createContext, useState, useContext } from 'react';

// Create a context
const LoaderContext = createContext();

// Create a provider component
export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const showLoader = () => setLoading(true);
  const hideLoader = () => setLoading(false);

  const value = {
    loading,
    showLoader,
    hideLoader,
  };

  return <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>;
};

// Create a custom hook to use the loader context
export const useLoader = () => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }
  return context;
};
