import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import PageHeaderTitle from '../commonComponents/PageHeaderTitle';
import { getExamDetails } from '../../services/exam';
import RenderQuestion from '../Dashboard/RenderQuestion';
import { ViewRemark } from '../Pages/EmptyPage/ViewRemark';

const ViewExamResultScreen = () => {
    const [examResults, setExamResults] = useState([]);
    const [imgModal, setImgModal] = useState(false);
    const [modalContent, setModalContent] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const testId = searchParams.get('testId');
        getExamQuestionList(testId)
    }, [])

    const getExamQuestionList = async (id) => {
        try {
            let response = await getExamDetails(id);
            if (response?.data?.data && response?.data?.data.length > 0) {
                setExamResults(response?.data?.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const handleImgClick = (event) => {
        if (event.target.tagName.toLowerCase() === 'img') {
            const imgSrc = event.target.getAttribute('src');
            setModalContent(imgSrc);
            setImgModal(true);
        }
    };


    return (
        <>
            <Fragment>
                <PageHeaderTitle title="Home" subtitle="View Result" />
                <Card>
                    <Card.Body>
                        <div className="responsive">
                            <Row className="mg-b-20">
                                <Col md={12} xs={12}>
                                    {
                                        examResults?.map((ques, index) => (
                                            <Row className="row-sm" key={index}>
                                                <Col md={12} xl={12} xs={12}>
                                                    <Card.Body className="card custom-card card-body">
                                                        <RenderQuestion data={ques} serialno={index} />
                                                        {
                                                            ques?.remarks ? <ViewRemark remarks={ques.remarks} isStyled={true} handleImgClick={handleImgClick} />
                                                                : ""
                                                        }
                                                        {
                                                            ques?.teachersRemark ? <ViewRemark remarks={ques.teachersRemark} isStyled={true} handleImgClick={handleImgClick} />
                                                                : ""
                                                        }
                                                    </Card.Body>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                </Col>
                            </Row>
                            <Modal show={imgModal} onClick={() => { setImgModal(false); }} size="fullscreen" backdrop="static">
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="example">
                                        <div>
                                            <img
                                                alt="media15"
                                                className="img-fluid"
                                                src={modalContent}
                                            />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </Card.Body>
                </Card>
            </Fragment>
        </>
    )
}

export default ViewExamResultScreen
