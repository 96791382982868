import React, { useState } from "react";
import {Card} from 'react-bootstrap';
import HighlightedText from "../../commonComponents/HighlightedText";

export function ViewRemark({remarks, isStyled, handleImgClick, searchText}) {
    return (
      <Card className="mt-2">
        <Card.Body>
          <div 
            className={`remark-container ${isStyled  ? 'remark-style' : ''}`} 
            onClick={(event)=> handleImgClick(event, remarks)} >
            {
              searchText ? 
              <HighlightedText text={remarks} searchTerm={searchText} />
              : <div className="text-animation"><p dangerouslySetInnerHTML={{ __html: remarks }}></p></div>
            }
          </div>
        </Card.Body>
      </Card>
    );
  }
