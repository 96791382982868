import axiosInstance from "../axios"


export const logInfo = async(data) => {
     if (process.env.REACT_APP_LOG_IN_DB === "0")
    {   
        return await axiosInstance.post('/log', data);
    }
    else if (process.env.REACT_APP_LOG_IN_DB === "1")
    {   
        if ((data.activityid >= 100 && data.activityid < 200) || data.activityid === 500)
        {    
            return await axiosInstance.post('/log', data);
        }
    }
    else if (process.env.REACT_APP_LOG_IN_DB === "2")
    {   
        if ((data.activityid >= 200 && data.activityid < 300) || data.activityid === 500)
        {    
            return await axiosInstance.post('/log', data);
        }
    }
    else if (process.env.REACT_APP_LOG_IN_DB === "3")
    {   
        if ((data.activityid >= 300 && data.activityid < 400) || data.activityid === 500)
        {    
            return await axiosInstance.post('/log', data);
        }
    }
    else if (process.env.REACT_APP_LOG_IN_DB === "4")
    {   
        if (data.activityid >= 400 && data.activityid <= 500)
        {    
            return await axiosInstance.post('/log', data);
        }
    }
}

