/* Npm Dependencies */
import React, { memo } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
/* Local dependencies */
import Auth from "../Authentication/auth";
import Loader from "../layouts/Loader/Loader";
import Home from "../components/Dashboard/Home";
import { useAuth } from "../AuthContext";
import ViewExamResultScreen from "../components/ViewExamResult";

// AdvanceUi
const AppComp = React.lazy(() => import("../components/app"));
const Question = React.lazy(() =>
  import("../components/Pages/EmptyPage/Question")
);
const AuthLogin = React.lazy(() => import("../Authentication/Login"));
const AuthSignup = React.lazy(() => import("../Authentication/Signup"));
const Exam = React.lazy(() => import("../components/Exams/Exam"));
const Test = React.lazy(() => import("../components/Exams/Test"));
const Profile = React.lazy(() => import("../components/profile/Profile"));
const Landingpageapp = React.lazy(() => import("../components/Landingpageapp"));
const NotFound = React.lazy(() => import("../components/Pages/404"));

const RootRoutes = () => {
  const { isLoggedIn } = useAuth();
  return (
    <BrowserRouter>
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}/`} element={<Auth />}>
            <Route index element={<Landingpageapp />} />
            {/* <Route index element={<AuthLogin />} /> */}
            <Route
              path={`${process.env.PUBLIC_URL}/authentication/login`}
              element={isLoggedIn ? <Navigate to="/" /> : <AuthLogin />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/authentication/signup`}
              element={<AuthSignup />}
            />
          </Route>
          {/* Specify the element prop for the App route */}
          <Route path={`${process.env.PUBLIC_URL}/`} element={<AppComp />}>
            {/* Pages */}
            <Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/question`}
                element={<Question />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/exams/test`}
                element={<Test />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/pages/profile`}
                element={<Profile />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/home`}
                element={<Home />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/exams/exam`}
                element={<Exam />}
              />
              <Route 
                  path={`${process.env.PUBLIC_URL}/home/view-results`}
                  element={<ViewExamResultScreen />}
                />
            </Route>
            {/* Errors */}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default memo(RootRoutes);
