import axiosInstance from "../axios"


export const login = async (data) => {
    return await axiosInstance.post(`/login`, data);
}

export const generateOtp = async(mobile) => {
    return await axiosInstance.post('/generate-otp', {mobile});
}

export const verifyOtpSrv = async(data) => {
    return await axiosInstance.post('/verify-otp', data);
}

export const updatePassword = async(data) => {
    return await axiosInstance.put('/update-password', data);
}

export const resendOTP = async(data) => {
    return await axiosInstance.put('/resend-otp', data);
}

export const isUserLoggedIn = async(token) => {
    return await axiosInstance.post('/get-user-details', { token});
}
