import React, { Fragment } from 'react';
import { Row, Col, Modal, Card } from 'react-bootstrap';
import RenderQuestion from './RenderQuestion';
import { ViewRemark } from '../Pages/EmptyPage/ViewRemark';

const ViewResults = ({ open, setOpen, data, selectedExam }) => {
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Fragment>
            <Modal show={open} size="lg">
                <Modal.Header
                    closeButton
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <h6>{selectedExam.name}</h6>
                </Modal.Header>
                <Modal.Body style={{height: '430px', overflowX: 'auto'}}>
                    <Row className="mg-b-20">
                        <Col md={12} xs={12}>
                                {
                                    data?.map((ques, index) => (
                                        <Row className="row-sm" key={index}>
                                            <Col md={12} xl={12} xs={12}>
                                                <Card.Body className="card custom-card card-body">
                                                    <RenderQuestion data={ques} serialno={index} />
                                                    {ques.remarks ? <ViewRemark remarks={ques.remarks} isStyled={false} />:null}
                                                </Card.Body>
                                            </Col>
                                        </Row>
                                    ))
                                }
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default ViewResults;
