import { toast } from "react-toastify";

export const generateUniqueBrowserId = () => {
    const navigatorInfo = `${navigator.userAgent}${navigator.platform}${navigator.language}`;
    const screenInfo = `${window.screen.width}${window.screen.height}${window.screen.colorDepth}`;
    const uniqueSeed = navigatorInfo + screenInfo;
    const hash = btoa(uniqueSeed); // Base64-encoded hash
    return hash;
}

export const getToken = () => {
    return localStorage.getItem("token");
}

export const toastSuccess = (text) => {
    toast.success(
        <p className="mx-2 tx-16 d-flex align-items-center mb-0 ">
            {text}
        </p>,
        {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            theme: "colored",
        }
    );
}

export const toastError = (text) => {
    toast.error(
        <p className="mx-2 tx-16 d-flex align-items-center mb-0 ">
            {text}
        </p>,
        {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            theme: "colored",
        }
    );
}

export const inputOnlyNum = (e, length) => {
    const validKeys = /[0-9\b]/; // Allow only numeric characters and control keys
    if (!validKeys.test(e.key) || e.target.value.length >= 10) {
        e.preventDefault();
    }
}

export const organizeCategories = (data) => {
    const categoriesMap = {};
    data.forEach(item => {
        const parentId = item.parent_id;
        if (parentId === "0") {
            categoriesMap[item.value] = { ...item, subcategories: [] };
        } else {
            let parent_idArr = parentId.split(',');
            for (let pid of parent_idArr) {
                if (categoriesMap[pid]) {
                    categoriesMap[pid].subcategories.push(item);
                } else {
                    categoriesMap[pid] = { subcategories: [item] };
                }
            }
        }
    });
    const organizedCategories = Object.values(categoriesMap);
    return organizedCategories;
}