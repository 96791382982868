import React, { Fragment } from 'react';
const RenderQuestion = ({ serialno, data }) => {
    return (
        <Fragment>
            <h4 className="main-content-label tx-dark tx-medium mg-b-10">
                {serialno + 1}. {data.question}
            </h4>
            <div className="custom-controls-stacked">
                {['A', 'B', 'C', 'D'].map((val, id) => (
                    <label className="custom-control custom-radio custom-control-md" key={id}>
                        <input
                            type="radio"
                            className="custom-control-input"
                            name={`radioBtn${serialno}`}
                            value={data[`option${id + 1}`]}
                            disabled
                            checked={data[`option${id + 1}`] === data['correctOption']}
                        />
                        <span className={`custom-control-label custom-control-label-md tx-16 ${data[`option${id + 1}`] === data['correctOption'] ? 'text-success' :
                                data[`userOption${val}`] ? 'text-danger' : 'text-black'
                            }`}>
                            {data[`option${id + 1}`]}
                        </span>
                    </label>
                ))}
            </div>
        </Fragment>
    )
}

export default RenderQuestion;