import { Breadcrumb } from "react-bootstrap";


const PageHeaderTitle = ({title, subtitle}) => {
    return (
        <div className="page-header-title">
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="#"> {title} </Breadcrumb.Item>
                    <Breadcrumb.Item active>{subtitle}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
        </div>
    )
}

export default PageHeaderTitle;