/* Npm Dependencies */
import React, { Fragment, memo } from "react";
import { ToastContainer } from "react-toastify";
/* Local dependencies */
import { setupInterceptors } from "./services/axios";
import { useLoader } from "./LoaderContext";
import RootRoutes from "./Routes/RootRoutes";

const App = () => {
  const { showLoader, hideLoader } = useLoader();
  setupInterceptors(showLoader, hideLoader);
  return (
    <Fragment>
      <RootRoutes />
      <ToastContainer />
    </Fragment>
  );
};

export default memo(App);
