import React, { Fragment, useEffect, useState } from 'react';
import PageHeaderTitle from '../commonComponents/PageHeaderTitle';
import { Card, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { getExamDetails, getTotalExamAttempts } from '../../services/exam';
import { format } from 'date-fns';
import ViewResults from './ViewResults';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { logInfo } from '../../services/logger';

const Home = () => {
   const navigate = useNavigate();
   const [testData, setTestData] = useState([]);
   const [viewModal, setViewModal] = useState(false);
   const [examResults, SetExamResults] = useState([]);
   const [selectedExam, setSelectedExam] = useState({});

   const handleView = async (row) => {
        setSelectedExam(row);
        let {data: {data: RS}} = await getExamDetails(row.serialnumber);
        await logInfo({activityid: 401, testSerialNumber: 0})
        SetExamResults(RS);
        setViewModal(true);
   }

   const getResultDetails = async (row) => {
    navigate({
        pathname: "/home/view-results",
        search: createSearchParams({ testId: row.serialnumber }).toString()
    });
}

   useEffect(() => {
        const fetchData = async() => {
          try {
            let {data: {data: TD}} = await getTotalExamAttempts();
            await logInfo({activityid: 400, testSerialNumber: 0})
            setTestData(TD);
          } catch (error) {
            console.log(error);
          }
        }
        fetchData()
   }, []);
  const columns = [
    {
      name: 'S.NO',
      selector: (row, index) => index + 1,
      sortable: true,
      width: '8%'
    },
    {
      name: 'NAME',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'View',
      cell: (row) => {
          return (
              <div>
                  <Button
                    className=" btn-icon-text my-2 me-2 btn btn-secondary"
                    onClick={() => {
                      getResultDetails(row)
                    }}
                    type="button"
                  >
                    View Result
                  </Button>
              </div>
          );
      }
    },
    {
      name: 'NO OF QUESTIONS',
      selector: (row) => row.totalnoofque,
      sortable: true,
    },
    {
      name: 'CORRECT/INCORRECT',
      selector: (row) => row.noofcorrectans,
      cell: (row) => <div dangerouslySetInnerHTML={{ __html: `<span class="text-success">${row.noofcorrectans}</span>/<span class="text-danger">${row.noofincans}</span>` }} />,
      // selector: (row) => row.noofcorrectans+"/"+row.noofincans,
      sortable: true,
    },
    {
      name: 'UN-ATTEMPTED',
      cell: (row) => <div dangerouslySetInnerHTML={{ __html: `<span class="text-danger">${row.totalnoofque - (row.noofincans + row.noofcorrectans)}</span>` }} />,
      sortable: true,
    },
    // {
    //   name: ' TOTAL MARKS',
    //   selector: (row) => row.totalmarks,
    //   sortable: true,
    // },
    {
      name: 'TOTAL OBTAIN',
      selector: (row) => row.totalmarksobt,
      sortable: true,
    },
    {
      name: 'DATE',
      selector: (row) => format(new Date(row.date), 'yyyy/MM/dd HH:MM'),
      sortable: true,
    },
	
  ];

  const tableDatas = {
    columns,
    data: testData, // Pass the array of objects here
  };

  const myInlineStyle = {
    whiteSpace: 'normal !important'
  };

  return (
    <Fragment>
      <PageHeaderTitle title="Home" subtitle="Dashboard" />
      <Card>
        <Card.Body>
          <div className="responsive">
            <DataTableExtensions {...tableDatas}>
              <DataTable
                title="Exams"
                columns={columns}
                data={testData}
                pagination
              />
            </DataTableExtensions>
          </div>
        </Card.Body>
      </Card>
      <ViewResults open={viewModal} setOpen={setViewModal} data={examResults} selectedExam={selectedExam} />
    </Fragment>
  );
};

export default Home;
