import React, { createContext, useContext, useEffect, useState } from 'react';
import { isUserLoggedIn } from './services/auth';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    const getUserDetails = async() => {
        const token = sessionStorage.getItem('token');
        if (token) {
            let { data: {user, status} } = await isUserLoggedIn(token);
            setIsLoggedIn(status);
            setUserDetails(user);
        }
    }
    getUserDetails();
  }, []);

  const loginSuccess = () => {
    setIsLoggedIn(true);
  };

  const logoutSuccess = () => {
    sessionStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, loginSuccess, logoutSuccess, userDetails }}>
      {children}
    </AuthContext.Provider>
  );
};
