import React from 'react';

const HighlightedText = ({ text, searchTerm }) => {
  const highlightWord = (content, word) => {
    const replace = `<span style="background-color: #F1388B;">${word}</span>`;
    const regex = new RegExp(word, 'gi');
    return content.replace(regex, replace);
  };

  const highlightedContent = highlightWord(text, searchTerm);

  return <span dangerouslySetInnerHTML={{ __html: highlightedContent }} />;
};

export default HighlightedText;
