// Npm
import React from "react";
import ReactDOM from "react-dom/client";
// Local
import App from "./App";
import { AuthProvider} from "./AuthContext";
import { LoaderProvider } from "./LoaderContext";
import "./index.scss";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <LoaderProvider>
      <App />
    </LoaderProvider>
  </AuthProvider>
);




